$breakpoints: (
    "mobile-l": (min-width: 376px),
    "pad": (min-width: 768px),
    "desktop": (min-width: 1024px),
    "desktop-w": (min-width: 1280px),
    "desktop-l": (min-width: 1366px),
    "desktop-hd": (min-width: 1440px),
    "desktop-wild": (min-width: 1680px),
    "desktop-fullhd": (min-width: 1920px),
    "desktop-huge": (min-width: 2000px)
) !default;

// Mixin to manage responsive breakpoints
// @author Hugo Giraudel
// @param {String} $breakpoint - Breakpoint name
// @require $breakpoints
@mixin rwd($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "(+"Available breakpoints are: #{map-keys($breakpoints)}.");
    }
}

@mixin clearfix {
    zoom: 1;
    &::before {
        content: "";
        display: table;
    }
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin hover() {
    @media (hover: none) {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:active {
            @content;
        }
    }

    @media (hover: hover), all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:hover {
            @content;
        }
    }
}
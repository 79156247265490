nav {
    @apply fixed top-0 right-0 flex items-center justify-end w-full h-full z-40 pt-[72px] opacity-0 transition-opacity pointer-events-none overflow-hidden md:pt-[78px];

    .bg {
        @apply absolute z-0 top-0 left-0 w-full h-full bg-black/60 cursor-pointer;
    }

    &.open {
        @apply opacity-100 pointer-events-auto;

        ul {
            @apply translate-x-0;
        }
    }

    ul {
        @apply relative h-full py-12 px-10 bg-hover translate-x-full transition-[transform] delay-[0.2s];
    }

    li {
        @apply text-center mb-2;
    }

    .nav-unit {
        @apply cursor-pointer border-b border-[#434343]/20 inline-block py-4 font-noto font-normal text-center text-white text-[18px] leading-[1.45] md:text-[21px] lg:text-[24px];

        &.disabled {
            @apply text-white/40
        }
    }
}
.section-quiz {
    @apply relative w-full h-[200%] min-h-[540px] bg-theme/20;

    .quiz-wrapper {
        @apply sticky top-0 left-0 w-full h-1/2 bg-cover bg-[center_bottom] bg-no-repeat;

        .train {
            @apply absolute left-0 bottom-0 z-[0] w-full h-full content-[""] bg-cover bg-[10%_bottom] bg-no-repeat pointer-events-none lg:bg-[left_bottom];
        }

        .ground {
            @apply absolute left-0 bottom-0 z-[1] w-full h-full content-[""] bg-cover bg-[33%_bottom] bg-no-repeat pointer-events-none;
        }

        .balloon {
            @apply absolute bg-cover transition-opacity opacity-0 md:left-[calc(50%-290px)] md:top-[calc(50%-150px)] md:w-[70px] md:h-[70px] lg:w-[80px] lg:h-[80px] lg:left-[calc(50%-360px)];

            &.display {
                @apply opacity-100;
            }
        }
    }

    .main-scene {
        @apply absolute top-0 left-0 z-[1] w-full h-full flex flex-col items-center px-6 justify-center opacity-0 pointer-events-none -z-[1] transition-all;

        &.display {
            @apply opacity-100 pointer-events-auto z-0;
        }

        .title-group {
            @apply relative;
        }

        .quiz-title {
            @apply w-[78.6666%] mx-auto md:max-w-[526px] lg:max-w-[702px];

            img {
                @apply block w-full h-auto;
            }
        }

        .quiz-paragraph {
            @apply my-6 font-noto font-medium text-center text-[#434343] text-[16px] leading-[1.45] md:my-10 md:text-[28px] lg:text-[26px];

            // &.paragraph-pc {
            //     @apply hidden lg:block;
            // }

            // &.paragraph-mo {
            //     @apply block lg:hidden;
            // }

            .text-orange {
                @apply font-bold;
            }
        }

        .btn-group {
            @apply md:flex;
        }

        .btn {
            .text {
                @apply w-[184px] h-[64px] text-[18px] text-center md:h-[70px];
            }

            &-orange {
                @apply mb-4 md:mb-0 md:mr-6;
            }
        }

        .btn-info {
            @apply mt-4 font-noto font-normal text-center text-[14px] text-blue underline underline-offset-2 cursor-pointer md:mt-6 md:text-[18px];
        }
    }

    .intro-scene {
        @apply absolute top-0 left-0 z-[1] w-full h-full flex flex-col items-center justify-center p-6 opacity-0 pointer-events-none -z-[1] transition-all;

        &.display {
            @apply opacity-100 pointer-events-auto z-0;

            .intro-paragraph {
                @apply opacity-100;
            }

            .data-paragraph {
                @apply opacity-100;
            }

            .btn-skip {
                @apply opacity-100;
            }
        }

        .intro-paragraph {
            @apply mb-4 font-noto font-medium text-center text-[#434343] text-[24px] leading-[1.45] opacity-0 transition-opacity duration-[0.5s] md:text-[40px];

            &:nth-child(1) {
                @apply delay-[1s];
            }

            &:nth-child(2) {
                @apply delay-[2.5s];
            }
        }

        .data-paragraph {
            @apply mb-6 font-noto font-medium text-center text-[#434343] text-[16px] leading-[1.45] align-sub opacity-0 transition-opacity duration-[0.5s] md:text-[24px];

            .text-data {
                @apply font-bold text-[24px] text-theme align-sub md:text-[40px];
            }

            .text-l {
                @apply text-[20px] align-sub md:text-[32px];
            }

            .br {
                @apply block sm:inline;
            }

            &:nth-child(1) {
                @apply delay-[2s];
            }

            &:nth-child(2) {
                @apply delay-[3.5s];
            }

            &:nth-child(3) {
                @apply delay-[5s];
            }
        }

        .btn-skip {
            @apply delay-[6.5s] mt-8 lg:mt-12 opacity-0 transition-opacity duration-[0.5s];
        }
    }

    .quiz-scene {
        @apply absolute top-0 left-0 z-[1] w-full h-full flex flex-col items-center justify-center p-6 opacity-0 pointer-events-none -z-[1] transition-all duration-[0.5s] overflow-hidden;

        &.display {
            @apply opacity-100 pointer-events-auto z-0;

            .question {
                &.display {
                    @apply pointer-events-auto;
                }
            }
        }

        .quiz-box {
            @apply relative max-w-[400px] mx-auto w-full bg-white rounded-[24px] p-4 px-6 md:max-w-[702px] md:py-8 md:px-16 lg:px-20 lg:max-w-[750px] 2xl:max-w-[900px];

            .bg {
                @apply absolute w-full h-full top-0 left-0 z-0 pointer-events-none rounded-[24px] overflow-hidden;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 31 30'%3E%3Crect fill='%23FFF' width='30' height='30'/%3E%3C/svg%3E"), linear-gradient(0deg, rgba(239,248,254,1) 0%, rgba(255,255,255,1) 50%, rgba(239,248,254,1) 100%);
                background-size: 31px 30px, 100% 100%;

                &::after {
                    @apply absolute content-[""] w-full h-full top-0 left-0 z-0 pointer-events-none mix-blend-multiply;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 30 31'%3E%3Crect fill='%23FFF' width='30' height='30'/%3E%3C/svg%3E"), linear-gradient(90deg,rgba(239,248,254,1) 0%, rgba(255,255,255,1) 50%, rgba(239,248,254,1) 100%);
                    background-size: 30px 31px, 100% 100%;
                }
            }
        }

        .quiz-title {
            @apply relative font-noto font-black text-theme italic text-[24px] leading-[1.45] md:text-[42px] 2xl:text-[42px];
        }

        .quiz-answers {
            @apply relative flex flex-wrap items-start mt-4 -mx-2 [counter-reset:list-counter] md:-mx-3;

            button {
                @apply relative text-left border-2 border-[#676767] my-2 mx-2 px-3 py-2 pl-8 font-noto font-bold text-[#676767] text-[20px] leading-[1.45] [counter-increment:list-counter] transition-all md:flex-[0_0_auto] md:py-3 md:pl-11 md:text-[28px] md:my-3 md:mx-3 2xl:pl-14;

                &::before {
                    @apply absolute left-2 content-[counter(list-counter,upper-alpha)".\_"] md:left-3;
                }
            }

            .btn-img {
                @apply m-0 border-0 text-left w-[calc(50%-20px)] md:w-auto;

                &:first-child {
                    @apply mr-5 md:mr-10 lg:mr-20;
                }

                img {
                    @apply mt-2 w-full h-auto max-w-[180px] transition-all;
                }

                // @include hover {
                //     @apply bg-blue border-blue text-white;

                //     img {
                //         filter: brightness(0) invert(1);
                //     }
                // }
            }

            .btn-desktop {
                @apply p-4 flex items-center justify-start w-full md:w-[calc(100%-24px)] mx-0 border-0 text-left border-[#434343] border-[1px] bg-white md:pl-14 md:pr-10;

                .image-wrapper {
                    @apply w-full ml-4 flex justify-center;
                }

                .pic-mo {
                    @apply block md:hidden;
                }

                .pic-pc {
                    @apply hidden md:block md:w-full;
                }
            }
        }

        .question {
            @apply absolute opacity-0 pointer-events-none -z-[1] transition-opacity duration-[0.5s];

            &.display {
                @apply relative opacity-100 z-0;
            }
        }

        .question_1 {
            .quiz-answers {
                @apply flex-col;
            }
        }

        .question_4 {
            button {
                @apply flex-[0_1_auto] text-left;
            }
        }

        .quiz-nav {
            @apply relative flex items-center justify-center mt-6;
        }

        .quiz-unit {
            @apply block w-10 h-2 mx-1 rounded-full bg-[rgba(248,148,47,0.2)] cursor-pointer;

            &.now-selected {
                @apply bg-theme;
            }

            &.disabled {
                @apply cursor-auto;
            }
        }

        .deco-group {
            @apply absolute top-0 left-0 w-full h-full pointer-events-none z-[1];
        }

        .deco {
            @apply opacity-0 transition-opacity;

            &.display {
                @apply opacity-100;
            }
        }

        .deco-leaf {
            @apply absolute bg-[100%_auto] bg-no-repeat;

            &-left {
                @apply top-[calc(50%-180px)] left-[calc(50%-280px)] w-[110px] h-[278px] md:top-[calc(50%-330px)] md:left-[calc(50%-520px)] md:w-[222px] md:h-[556px] lg:left-[calc(50%-550px)] 2xl:left-[calc(50%-600px)];
            }

            &-right {
                @apply top-[calc(50%-40px)] right-[calc(50%-270px)] w-[190px] h-[211px] md:top-[calc(50%-140px)] md:right-[calc(50%-480px)] md:w-[378px] md:h-[420px] lg:right-[calc(50%-620px)];
            }
        }

        .deco-backpack {
            @apply absolute bg-[100%_auto] bg-no-repeat md:w-[85px] md:h-[112px] md:top-[calc(50%+100px)] md:left-[calc(50%-410px)] lg:left-[calc(50%-500px)];
        }

        .deco-luggage {
            @apply absolute top-[calc(50%-200px)] left-[calc(50%+150px)] w-[40px] h-[64px] bg-[100%_auto] bg-no-repeat md:w-[80px] md:h-[128px] md:top-[calc(50%-300px)] md:left-[calc(50%+240px)] lg:left-[calc(50%+360px)];
        }

        .deco-computer {
            @apply absolute top-[calc(50%-320px)] right-[calc(50%-220px)] w-[74px] h-[63px] bg-[100%_auto] bg-no-repeat md:w-[145px] md:h-[126px] md:top-[calc(50%-340px)] md:right-[calc(50%-320px)] lg:right-[calc(50%-500px)];
        }

        .deco-stationery {
            @apply absolute bg-[100%_auto] bg-no-repeat md:w-[82px] md:h-[110px] md:top-[calc(50%)] md:right-[calc(50%-400px)] lg:right-[calc(50%-500px)];
        }

        .deco-shrimp {
            @apply absolute top-[calc(50%-150px)] right-[calc(50%-200px)] w-[80px] h-[76px] bg-[100%_auto] bg-no-repeat md:w-[141px] md:h-[169px] md:top-[calc(50%-200px)] md:left-[calc(50%-480px)] md:right-auto lg:left-[calc(50%-540px)];
        }

        .deco-fish {
            @apply absolute bg-[100%_auto] bg-no-repeat lg:w-[195px] lg:h-[126px] lg:top-[calc(50%-120px)] lg:right-[calc(50%-500px)];
        }

        .deco-cash {
            @apply absolute bg-[100%_auto] bg-no-repeat;

            &-left {
                @apply top-[calc(50%+160px)] left-[calc(50%-260px)] w-[92px] h-[76px] md:top-[calc(50%+180px)] md:left-[calc(50%-420px)] md:w-[184px] md:h-[152px] lg:left-[calc(50%-480px)];
            }

            &-right {
                @apply lg:w-[148px] lg:h-[150px] lg:top-[calc(50%+120px)] lg:right-[calc(50%-460px)];
            }
        }

        .deco-money {
            @apply absolute bg-[100%_auto] bg-no-repeat;

            &-left {
                @apply lg:top-[calc(50%-250px)] lg:left-[calc(50%-580px)] lg:w-[246px] lg:h-[370px] xl:left-[calc(50%-650px)];
            }

            &-right {
                @apply top-[calc(50%-180px)] right-[calc(50%-280px)] w-[156px] h-[376px] md:top-[calc(50%-220px)] md:right-[calc(50%-400px)] md:w-[208px] md:h-[502px] lg:right-[calc(50%-480px)];
            }
        }
    }

    .outcome-scene {
        @apply relative pt-[70px] pb-10 mx-5 h-full overflow-y-scroll pointer-events-none opacity-0 transition-opacity duration-[0.5s] md:pt-[78px] md:mx-auto md:max-w-[541px];

        &.display {
            @apply opacity-100 pointer-events-auto z-[2];
        }

        .hint-text {
            @apply mt-3 font-noto font-normal text-center text-[#434343] text-[14px] ;
        }

        .outcome-image {
            @apply relative block w-full overflow-hidden max-w-[506px] mx-auto;

            canvas {
                @apply absolute mx-auto opacity-0;
            }

            img {
                @apply relative block w-full h-auto;
            }

            .btn-link {
                @apply absolute h-0 overflow-hidden block w-[calc(108/415*100%)] pt-[calc(108/415*100%)] bottom-0 right-0 opacity-0 z-[2];
            }
        }

        .btn-group {
            @apply flex flex-wrap justify-center mt-4 md:mt-6;

            &.large {
                @apply max-w-[292px] mx-auto md:max-w-[324px] lg:max-w-none;

                .btn-blue {
                    @apply w-[268px] md:w-[324px] lg:w-[auto];
                }
            }

            .btn {
                @apply m-3;

                .text {
                    @apply text-[16px] md:text-[20px];
                }
            }
        }
    }
}
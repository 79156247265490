.popup {
    @apply fixed top-0 left-0 w-full h-full flex items-center justify-center p-6 opacity-0 pointer-events-none -z-[1];

    &.display {
        @apply opacity-100 pointer-events-auto z-50;
    }

    .bg {
        @apply absolute top-0 left-0 w-full h-full bg-black/50 cursor-pointer;
    }

    &-box {
        @apply relative py-10 px-6 max-w-[700px] bg-white rounded-[24px] md:px-12 md:py-[74px];

        .input-group {
            @apply relative my-6 md:my-10;

            &.error {
                .input-error {
                    @apply opacity-100 pointer-events-auto;
                }
            }
        }

        .input-error {
            @apply absolute right-0 opacity-0 pointer-events-none font-noto font-normal text-[#D95523] text-[12px] leading-[1.45];
        }

        input[type=text] {
            @apply outline-none appearance-none p-3 rounded-[8px] w-full block border border-[#A1A1A1] font-noto font-normal text-[#434343] text-[14px] md:text-[16px];

            &:focus {
                @apply border-theme;
            }
        }

        .checkbox-group {
            @apply relative font-noto font-normal text-[#434343] text-[14px] leading-[1.45] md:text-[16px] lg:text-[18px];

            label {
                @apply flex;
            }

            &.error {
                .input-error {
                    @apply opacity-100 pointer-events-auto;
                }
            }
        }

        .custom-checkbox {
            @apply block w-5 h-5 mr-2 flex-[0_0_auto] lg:mt-1;

            input[type=checkbox] {
                @apply rounded-none block w-full h-full border border-black;
            }
        }

        .term, .info {
            @apply text-[#046486] underline underline-offset-2 cursor-pointer;
        }

        .btn {
            @apply mx-auto mt-6 md:mt-10;

            .text {
                @apply py-4 px-5;
            }
        }
    }

    &-title {
        @apply mt-5 font-noto font-bold text-theme text-[24px] leading-[1.45] md:text-[36px] md:mt-0 lg:text-[40px];
    }

    &-paragraph {
        @apply block mb-2 font-noto font-normal text-[#434343] text-[14px] leading-[1.45] md:text-[21px] md:mb-4 lg:text-[24px];
    }
}

.popup_info {
    .bg {
        @apply bg-black/50;
    }

    &.no-bg {
        .bg {
            @apply bg-transparent;
        }
    }
}

.popup_term, .popup_info {
    @apply fixed top-0 left-0 w-full h-full flex items-center justify-center p-6 opacity-0 pointer-events-none -z-[1];

    &.display {
        @apply opacity-100 pointer-events-auto z-50;
    }

    .bg {
        @apply absolute top-0 left-0 w-full h-full cursor-pointer;
    }

    .term, .info {
        &-box {
            @apply relative flex flex-col w-full h-full max-w-[600px] px-4 bg-white rounded-[24px] md:px-6 lg:px-10;

            .box-bg {
                @apply absolute w-full h-full top-0 left-0 z-0 pointer-events-none rounded-[24px] overflow-hidden z-0;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 31 30'%3E%3Crect fill='%23FFF' width='30' height='30'/%3E%3C/svg%3E"), linear-gradient(0deg, rgba(239,248,254,1) 0%, rgba(255,255,255,1) 50%, rgba(239,248,254,1) 100%);
                background-size: 31px 30px, 100% 100%;

                &::after {
                    @apply absolute content-[""] w-full h-full top-0 left-0 z-0 pointer-events-none mix-blend-multiply;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 30 31'%3E%3Crect fill='%23FFF' width='30' height='30'/%3E%3C/svg%3E"), linear-gradient(90deg,rgba(239,248,254,1) 0%, rgba(255,255,255,1) 50%, rgba(239,248,254,1) 100%);
                    background-size: 30px 31px, 100% 100%;
                }
            }

            .btn {
                @apply my-6 mx-auto;
            }

            ol {
                @apply list-decimal pl-[30px];

                ol {
                    @apply list-[lower-alpha] pl-6;
                }
            }

            .note {
                @apply ml-2;
            }
        }

        &-container {
            @apply relative w-full h-full overflow-y-scroll pb-10;
        }

        &-title {
            @apply relative pt-10 my-6 font-noto font-medium text-theme text-[24px] text-center leading-[1.45] md:mt-10 md:text-[28px] lg:mt-12 lg:text-[32px];
        }

        &-subtitle {
            @apply mt-6 mb-1 font-noto font-normal text-[#434343] text-[18px] leading-[1.45] md:text-[21px] lg:text-[24px];
        }

        &-paragraph {
            @apply font-noto font-normal text-[#434343] text-[16px] leading-[1.45] lg:text-[18px];
        }

        &-li {
            @apply relative block pl-[30px];

            &::before {
                @apply absolute top-0 left-[6px] block content-[""] w-1 h-1 bg-[#434343] rounded-full mt-3 mx-2;
            }
        }

        &-list {
            @apply font-noto font-normal text-[#434343] text-[16px] leading-[1.45] lg:text-[18px];

            ul {
                @apply pl-5;
            }

            a {
                @apply underline underline-offset-2;
            }
        }
    }
}

.popup_winner {
    @apply fixed top-0 left-0 w-full h-full flex items-center justify-center p-6 opacity-0 pointer-events-none -z-[1];

    &.display {
        @apply opacity-100 pointer-events-auto z-50;
    }

    .bg {
        @apply absolute top-0 left-0 w-full h-full bg-black/50 cursor-pointer;
    }

    .winner {
        &-box {
            @apply overflow-x-hidden relative flex flex-col w-full h-full max-w-[840px] bg-white rounded-[24px] px-2;

            .box-bg {
                @apply absolute w-full h-full top-0 left-0 z-0 pointer-events-none rounded-[24px] overflow-hidden z-0;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 31 30'%3E%3Crect fill='%23FFF' width='30' height='30'/%3E%3C/svg%3E"), linear-gradient(0deg, rgba(239,248,254,1) 0%, rgba(255,255,255,1) 50%, rgba(239,248,254,1) 100%);
                background-size: 31px 30px, 100% 100%;

                &::after {
                    @apply absolute content-[""] w-full h-full top-0 left-0 z-0 pointer-events-none mix-blend-multiply;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 30 31'%3E%3Crect fill='%23FFF' width='30' height='30'/%3E%3C/svg%3E"), linear-gradient(90deg,rgba(239,248,254,1) 0%, rgba(255,255,255,1) 50%, rgba(239,248,254,1) 100%);
                    background-size: 30px 31px, 100% 100%;
                }
            }
        }

        &-container {
            @apply relative w-full h-full overflow-y-scroll pb-10;
        }

        &-title {
            @apply relative mt-20 mb-6 font-noto font-medium text-theme text-[28px] text-center leading-[1.45] md:text-[40px];
        }

        &-list {
            @apply px-8 md:px-10;
        }

        &-unit {
            @apply py-8 border-t-2 border-[#9FA0A0] border-dashed text-center md:flex md:flex-wrap md:justify-center md:items-center md:text-left;

            &:nth-child(1) {
                .awards-title {
                    @apply text-[#F67318];
                }

                .awards-winner {
                    @apply bg-[#F67318];
                }
            }

            &:nth-child(2) {
                .awards-title {
                    @apply text-[#F67157];
                }

                .awards-winner {
                    @apply bg-[#F67157];
                }
            }

            &:nth-child(3) {
                .awards-title {
                    @apply text-[#57A2F6];
                }

                .awards-winner {
                    @apply bg-[#57A2F6];
                }
            }

            &:nth-child(4) {
                .awards-title {
                    @apply text-[#8565C6];
                }

                .awards-winner {
                    @apply relative mt-6 py-4 bg-[#8565C6] leading-[2.25] md:w-full md:flex md:justify-center md:px-10;

                    &::before {
                        @apply hidden absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[2px] h-[calc(100%-40px)] content-[""] bg-[#9FA0A0] md:block; 
                    }
                }

                .winner-group {
                    @apply md:w-[270px];

                    &:first-child {
                        @apply md:mr-10;
                    }
                }
            }

            &:last-child {
                .awards-winner {
                    @apply text-[14px] md:text-[20px];
                }
            }
        }

        &-pic {
            img {
                @apply block mx-auto md:mr-8;
            }
        }

        &-note {
            @apply text-[#4C4C4C] font-noto font-normal text-[20px] leading-[1.45];

            span {
                @apply underline underline-offset-2 text-[#2A78D0];
            }
        }
    }

    .awards {
        &-title {
            @apply mt-3 font-noto font-bold text-[28px] leading-[1];
        }

        &-quota {
            @apply mt-1 block text-[18px] leading-[1.45] md:mt-0 md:inline-block;
        }

        &-paragraph {
            @apply text-[#4C4C4C] mt-1 mb-3 font-noto font-normal text-[18px] leading-[1.45] md:text-[28px];
        }

        &-winner {
            @apply inline-block px-4 py-1 rounded-[18px] text-white font-noto font-normal text-[16px] leading-[1.45] [text-decoration:none] pointer-events-none md:text-[24px];
        }
    }
}


.popup_video {
    @apply fixed top-0 left-0 w-full h-full flex items-center justify-center p-6 opacity-0 pointer-events-none -z-[1];

    &.display {
        @apply opacity-100 pointer-events-auto z-50;
    }

    .bg {
        @apply absolute top-0 left-0 w-full h-full bg-black/50 cursor-pointer;
    }

    .content-group {
        @apply relative flex flex-col items-end w-full lg:w-[76%] max-w-[1600px];

        .btn-close {
            @apply relative mb-2 z-[2] right-0 top-0;
        }
    }

    .video-wrapper {
        @apply relative w-full pt-[calc(100%/16*9)] max-w-[1600px] max-h-[900px] bg-black;

        iframe {
            @apply absolute w-full h-full top-0 left-0 object-cover z-[0];
        }
    }
}
@keyframes switching {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes switching-r {
    0% {
        opacity: 1;
    }

    10% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

header {
    @apply fixed flex items-center justify-between top-0 left-0 w-full h-[72px] px-6 py-4 bg-white z-50 md:h-[78px];

    .logo {
        @apply h-full;

        svg {
            @apply block w-auto h-full;
        }
    }

    .btn-group {
        @apply flex items-center justify-between;
    }

    .btn {
        @apply mr-4 md:mr-8;

        .text {
            @apply relative text-[16px] md:text-[18px];

            &::after {
                @apply ml-2 -mr-2;
            }
        }

        i {
            @apply block w-full not-italic;

            &:first-child {
                @apply animate-[switching_2s_infinite];
            }

            &:last-child {
                @apply left-4 absolute animate-[switching-r_2s_infinite]
            }
        }
    }

    .btn-menu {
        @apply flex items-center justify-center w-10 h-10;

        &.open {
            .line {
                @apply rotate-[45deg];

                &::before {
                    @apply rotate-[90deg] top-0;
                }

                &::after {
                    @apply bottom-0;
                }
            }
        }

        .line {
            @apply relative block w-7 h-[2px] bg-theme transition-[transform];

            &::before {
                @apply absolute block top-[calc(100%+5px)] left-0 w-full h-full content-[""] bg-inherit transition-[transform];
            }

            &::after {
                @apply absolute block bottom-[calc(100%+5px)] left-0 w-full h-full content-[""] bg-inherit transition-[transform];
            }
        }
    }
}
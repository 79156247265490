@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./base/_mixin.scss";

::-webkit-input-placeholder {
    color: #A1A1A1;
}
::-moz-placeholder {
    color: #A1A1A1;
}
:-ms-input-placeholder {
    color: #A1A1A1;
}
:-moz-placeholder {
    color: #A1A1A1;
}

* {
    @apply box-border;
    scrollbar-width: thin;
    scrollbar-color: rgba(#272B2D, 0.3) transparent;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(#272B2D, 0.3);
    border-radius: 100px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}


html {
    @apply h-full;
}

body {
    @apply h-full pt-[72px] md:pt-[78px];
}

footer {
    @apply py-3 bg-theme;
}

#root {
    @apply h-full;
}

.btn {
    @apply relative block border transition-[border-color];

    &-arrows {
        @apply absolute z-[2] px-10 w-full items-center justify-between top-1/2 -translate-y-1/2 pointer-events-none hidden xl:flex;
    }

    &-prev {
        @apply flex items-center justify-center w-10 pointer-events-auto;

        &.disable {
            @apply opacity-0 pointer-events-none;
        }
    }

    &-next {
        @apply flex items-center justify-center w-10 pointer-events-auto;

        &.disable {
            @apply opacity-0 pointer-events-none;
        }
    }

    &:active {
        .text {
            @apply translate-x-0 translate-y-0;
        }
    }

    &-black {
        @apply border-[#535150];

        .text {
            @apply bg-[#535150];
        }

        @include hover {
            @apply border-[#373533];

            .text {
                @apply bg-[#373533];
            }
        }
    }

    &-orange {
        @apply border-theme;

        .text {
            @apply bg-theme;
        }

        @include hover {
            @apply border-hover;

            .text {
                @apply bg-hover;
            }
        }
    }

    &-blue {
        @apply border-blue;

        .text {
            @apply bg-blue;
        }

        @include hover {
            @apply border-hoverBlue;

            .text {
                @apply bg-hoverBlue;
            }
        }
    }

    .text {
        @apply relative flex flex-row items-center justify-center px-4 py-2 font-noto font-normal text-white -translate-x-[3px] -translate-y-[3px] transition-[transform,background-color];

        &::after {
            @apply ml-4 -mr-1 block content-[""] border-[length:5px_6px] border-[transparent_transparent_transparent_#FFF];
        }
    }

    &-goTop {
        @apply fixed bottom-10 right-4 flex items-center justify-center w-10 h-10 rounded-full bg-theme transition-[background-color,opacity] opacity-0 pointer-events-none md:w-14 md:h-14;

        &.display {
            @apply opacity-100 pointer-events-auto;
        }

        @include hover {
            @apply bg-hover;
        }
    }

    &-close {
        @apply absolute flex items-center justify-center top-4 right-4 w-10 h-10;

        &::before, &::after {
            @apply absolute content-[""] w-8 h-[2px] bg-theme;
        }
        &::before {
            @apply rotate-[45deg];

        }

        &::after {
            @apply -rotate-[45deg];
        }
    }
}

@import "./components/header.scss";
@import "./components/nav.scss";

.text-red {
    @apply text-hover;
}

.text-orange {
    @apply text-theme;
}

.pagination-list {
    @apply mt-6 flex items-center justify-center lg:mt-10;

    .pagination-unit {
        @apply block w-3 h-3 mx-1 bg-[#D2D6D3] rounded-full cursor-pointer;

        &.active {
            @apply bg-theme;
        }
    }
}

@keyframes scroll {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100%);
    }
}

.section {
    &-main {
        @apply relative w-full h-full min-h-[540px]; //bg-[center_top] bg-cover;

        video {
            @apply z-0 absolute w-full h-full object-cover pointer-events-none;
        }

        &::before {
            @apply absolute z-[1] content-[""] w-full h-full top-0 left-0 bg-[rgba(101,101,101,0.51)];
        }

        .content {
            @apply relative z-[2] w-full h-full flex flex-col items-center justify-center;
        }

        .main-title {
            @apply w-[68.2666%] mx-auto md:max-w-[460px] lg:max-w-[684px];

            img {
                @apply block w-full h-auto;
            }
        }

        .main-paragraph {
            @apply m-6 italic font-noto font-bold text-center text-white text-[18px] leading-[1.45] md:mx-20 md:text-[36px] lg:text-[32px];

            .br {
                @apply block w-0 h-0 overflow-hidden lg:inline;
            }
        }

        .btn-play {
            rect {
                @apply transition-[fill];
                fill: rgba(255, 255, 255, 0);
            }

            @include hover {
                rect {
                    fill: rgba(255, 255, 255, 0.2);
                }
            }
        }

        .scroll-hint {
            @apply absolute z-[2] right-3 bottom-0 w-2 flex flex-col items-center md:right-6 md:w-3;

            img {
                @apply block w-full h-auto;
            }

            .line {
                @apply relative w-[1px] h-[120px] mt-2 mr-[2px] bg-white/20 overflow-hidden md:h-[260px] md:mt-3;

                &::after {
                    @apply block content-[""] w-full h-[150%] bg-white/40 animate-[scroll_3s_infinite];
                }
            }

        }
    }

    &-KV {
        @apply relative w-full overflow-hidden;

        .pagination-list {
            @apply absolute mt-0 w-full left-0 bottom-5;
        }

        .kv-pc {
            @apply hidden lg:block;
        }

        .kv-mo {
            @apply block lg:hidden;
        }

        img {
            @apply w-screen;
        }

        // .kv-nav {
        //     @apply flex items-center justify-center absolute left-1/2 bottom-5 -translate-x-1/2 lg:bottom-10;

        //     .nav-unit {
        //         @apply block w-3 h-3 mx-[6px] bg-[#D2D6D3] rounded-full cursor-pointer cursor-pointer transition-all;

        //         &.active {
        //             @apply bg-theme;
        //         }

        //         @include hover {
        //             @apply bg-theme;
        //         }
        //     }
        // }

        // .kv-pic  {
        //     @apply absolute w-full h-full flex justify-center top-0 left-0 pt-20 opacity-0 pointer-events-none transition-opacity duration-[0.5s] lg:pr-[5%] lg:pt-[22vh] lg:justify-end 2xl:pr-[15%];

        //     &.display {
        //         @apply opacity-100 pointer-events-auto;
        //     }
        // }

        // .bg_mo {
        //     @apply absolute w-full h-full top-0 left-0 bg-[center_100%] bg-cover lg:hidden;
        // }

        // .bg_pc {
        //     @apply hidden absolute w-full h-full top-0 left-0 bg-[center_100%] bg-cover lg:bg-center lg:block;
        // }

        // .kv-text {
        //     @apply absolute top-12 left-1/2 -translate-x-1/2 w-[70%] lg:translate-x-0 lg:top-auto lg:left-[calc(50%+12vw)] lg:right-auto lg:max-w-[400px] 2xl:max-w-[556px];

        //     img {
        //         @apply block w-full h-auto;
        //     }
        // }
    }

    &-tholiday {
        @apply relative py-8 bg-no-repeat md:py-16 lg:py-20;
        background-position: center calc(100% + 1px), left top;
        background-repeat: no-repeat, no-repeat;
        background-size: 1920px auto, contain;

        @include rwd(desktop-w) {
            background-position: 0% calc(100% + 1px), left top;
        }

        .road-deco {
            @apply absolute z-0 top-0 h-0 w-full h-full bg-no-repeat bg-[50%_100%] bg-[length:1920px_auto] pointer-events-none opacity-0 transition-opacity xl:bg-[0%_100%];

            &.display {
                @apply opacity-100;
            }
        }

        .tholiday {
            &-list {
                &.hide {
                    .tholiday-unit {
                        &.is-active {
                            .tholiday-detail {
                                @apply opacity-0;
                            }
                        }
                    }
                }
            }

            &-title {
                @apply font-noto font-bold text-center text-theme text-[32px] leading-[1.45] md:text-[45px];
            }

            &-sub-title {
                @apply mb-6 font-noto font-medium text-center text-[#434343] text-[18px] leading-[1.45] md:mb-10 md:text-[28px] lg:mb-16;
            }

            &-image {
                @apply relative w-full pt-[calc(100%/53*50)] mb-6 transition md:mb-10 lg:mb-16 xl:mb-6;

                &::after {
                    @apply absolute z-[1] content-[""] top-0 left-0 w-full h-full border-[3px] border-white rounded-[25%] shadow-[0_1px_6px_0_#0000000A,0_0.46px_2px_2px_#00000014_inset] scale-[0.8] lg:border-[6px];
                }

                .image-wrapper {
                    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[calc(100%-2px)] h-[calc(100%-2px)] rounded-[25%] overflow-hidden scale-[0.8] bg-white;
                }

                img {
                    @apply absolute z-0 top-0 left-0 w-full h-full object-cover;
                }
            }

            &-unit {
                @apply transition-opacity xl:opacity-0;

                &.is-active {
                    @apply opacity-100;

                    .tholiday-image {
                        &::after {
                            @apply scale-[1];
                        }

                        .image-wrapper {
                            @apply scale-[1];
                        }
                    }

                    .tholiday-detail {
                        @apply opacity-100;

                        &.hide {
                            @apply opacity-0;
                        }
                    }
                }

                &.is-next {
                    @apply opacity-100;

                    img {
                        @apply opacity-50;
                    }
                }
            }

            &-logo {
                @apply mb-4;

                svg {
                    @apply mx-auto xl:mx-0;
                }
            }

            &-unit-title {
                @apply relative max-w-[300px] mx-auto font-noto font-medium text-center text-[#434343] text-[24px] leading-[1.45] md:text-[28px] lg:text-[32px] xl:mx-0 xl:text-left xl:w-[200px];

                &::before, &::after {
                    @apply block w-full h-[1px] bg-[linear-gradient(90deg,#F8942F_0%,rgba(248,148,47,0.2)_100%)] content-[""];

                }

                &::before {
                    @apply mb-2;
                }

                &::after {
                    @apply mt-2;
                }
            }

            &-unit-paragraph {
                @apply my-4 mx-auto font-noto font-normal text-center text-[#434343] text-[18px] leading-[1.45] md:text-[21px] lg:text-[24px] lg:my-5 xl:text-left;

                .text-orange {
                    @apply text-hover;
                }
            }

            &-detail {
                @apply opacity-0 transition-opacity xl:absolute xl:top-12 xl:right-[calc(100%+56px)] xl:whitespace-nowrap;

                .btn {
                    @apply w-[148px] mx-auto text-[20px] xl:mx-0;

                    .text {
                        @apply py-3;
                    }
                }
            }
        }
    }

    &-bundle {
        @apply relative py-8 bg-[linear-gradient(#FFF5E9_0%,#FFFFFF_19.48%,#FFEADD_100%)] md:py-16 lg:py-20;

        .bundle {
            &-list {
                &.hide {
                    .bundle-unit {
                        &.is-active {
                            .bundle-detail {
                                @apply opacity-0;
                            }
                        }
                    }
                }
            }

            &-title {
                @apply mb-6 font-noto font-medium text-center text-theme text-[32px] leading-[1.45] md:mb-10 md:text-[36px] lg:mb-16 lg:text-[40px];

                .br {
                    @apply block lg:inline;
                }
            }

            &-image {
                @apply relative w-full pt-[calc(100%/53*50)] mb-6 transition md:mb-10 lg:mb-16 xl:mb-6;

                &::after {
                    @apply absolute z-[1] content-[""] top-0 left-0 w-full h-full border-[3px] border-white rounded-[25%] shadow-[0_1px_6px_0_#0000000A,0_0.46px_2px_2px_#00000014_inset] scale-[0.8] lg:border-[6px];
                }

                .image-wrapper {
                    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[calc(100%-2px)] h-[calc(100%-2px)] rounded-[25%] overflow-hidden scale-[0.8] bg-white;
                }

                img {
                    @apply absolute z-0 top-0 left-0 w-full h-full object-cover;
                }
            }

            &-unit {
                @apply transition-opacity xl:opacity-0;

                &.is-active {
                    @apply opacity-100;

                    .bundle-image {
                        &::after {
                            @apply scale-[1];
                        }

                        .image-wrapper {
                            @apply scale-[1];
                        }
                    }

                    .bundle-detail {
                        @apply opacity-100;

                        &.hide {
                            @apply opacity-0;
                        }
                    }
                }

                &.is-next {
                    @apply opacity-100;

                    img {
                        @apply opacity-50;
                    }
                }
            }

            &-logo {
                @apply mb-4;

                svg {
                    @apply mx-auto;
                }
            }

            &-unit-type {
                @apply mb-2 font-noto font-bold text-center text-theme text-[32px] leading-[1.45] md:text-[36px] lg:text-[40px] xl:text-left;
            }

            &-unit-title {
                @apply relative mb-4 font-noto font-medium text-center text-[#434343] text-[18px] leading-[1.45] md:text-[21px] lg:text-[24px] xl:text-left;

                &::before, &::after {
                    @apply block w-full h-[1px] bg-[linear-gradient(90deg,#F8942F_0%,rgba(248,148,47,0.2)_100%)] content-[""];

                }

                &::before {
                    @apply mb-2;
                }

                &::after {
                    @apply mt-2;
                }
            }

            &-unit-paragraph {
                @apply my-4 mx-auto font-noto font-normal text-center text-[#434343] text-[14px] leading-[1.45] md:text-[16px] lg:text-[18px] xl:text-left;

                .text-orange {
                    @apply inline-block mb-1;
                }
            }

            &-detail {
                @apply opacity-0 transition-opacity xl:absolute xl:top-1/2 xl:-translate-y-1/2 xl:right-[calc(100%+56px)] xl:whitespace-nowrap;

                .btn {
                    @apply w-[148px] mx-auto text-[20px];

                    .text {
                        @apply py-3;
                    }
                }
            }
        }
    }

    &-card {
        @apply relative px-0;
    }

    &-tgo {
        @apply relative;
    }

    &-news {
        @apply relative py-10 md:py-20 lg:py-[160px];
        background-image: url("../../public/images/bg-flower.jpg"), linear-gradient(#F7F6ED 0%, #FCF9E7 100%);
        background-position: bottom center, center center;
        background-repeat: no-repeat, no-repeat;
        background-size: 100% auto, auto;

        .card-tgo {
            @apply opacity-30 w-full max-w-[800px] mx-auto border-b-[6px] border-theme rounded-[32px_0_32px_0] p-6 shadow-[0_0_8px_0_#0000001F] bg-white lg:flex lg:p-8 lg:p-12;

            &.is-active {
                @apply opacity-100;
            }

            .image-wrapper {
                @apply relative w-full pt-[calc(100%/2)] mb-6 rounded-[24px_0_24px_0] overflow-hidden lg:w-[55%] lg:mr-6 lg:mb-0 lg:pt-[calc(50%/5*3)] lg:mr-10;

                img {
                    @apply absolute top-0 left-0 w-full h-full object-cover
                }
            }

            .logo {
                @apply mb-4;
            }

            .card-title {
                @apply relative inline-block font-noto font-medium text-[#434343] text-[24px] leading-[1.45] md:text-[28px] lg:text-[32px] xl:text-left;

                &::before, &::after {
                    @apply block w-full h-[1px] bg-[linear-gradient(90deg,#F8942F_0%,rgba(248,148,47,0.2)_100%)] content-[""];

                }

                &::before {
                    @apply mb-2;
                }

                &::after {
                    @apply mt-2;
                }
            }

            .card-paragraph {
                @apply my-4 font-noto font-normal text-[#434343] text-[16px] leading-[1.45] md:text-[18px];
            }

            .btn {
                @apply w-[148px] text-[20px];

                .text {
                    @apply py-3;
                }
            }
        }
    }
}

@import "./components/quiz.scss";

.news {
    &-list {
        @apply relative mt-14 md:mt-[100px] lg:mt-[200px];
    }

    &-unit {
        @apply relative;

        .news-wrapper {
            @apply rounded-[50px] overflow-hidden bg-white scale-[0.8] md:rounded-[60px];

            * {
                @apply opacity-50;
            }

            &::after {
                @apply absolute z-[1] content-[""] top-0 left-0 w-full h-full border-[3px] border-white rounded-[50px] md:rounded-[60px] lg:border-[6px] pointer-events-none;
            }
        }

        .image-wrapper {
            @apply relative w-full pt-[calc(100%/22*15)];

            img {
                @apply absolute top-0 left-0 w-full h-full object-cover;
            }
        }

        &.is-active {
            .news-wrapper {
                @apply scale-[1];

                * {
                    @apply opacity-100;
                }
            }
        }
    }

    &-detail {
        @apply px-10 pt-4 pb-8 bg-white;

        .btn {
            @apply w-[148px] mx-auto text-[20px];

            .text {
                @apply py-3;
            }
        }
    }

    &-title {
        @apply relative inline-block font-noto font-medium text-[#434343] text-[24px] leading-[1.45] md:text-[28px] lg:text-[32px] xl:text-left;

        &::before, &::after {
            @apply block w-full h-[1px] bg-[linear-gradient(90deg,#F8942F_0%,rgba(248,148,47,0.2)_100%)] content-[""];

        }

        &::before {
            @apply mb-2;
        }

        &::after {
            @apply mt-2;
        }
    }

    &-paragraph {
        @apply mt-4 mb-7 mx-auto font-noto font-normal text-[#434343] text-[14px] leading-[1.45] md:text-[18px] xl:text-left;
    }
}

@import "./components/popup.scss";

.alert {
    @apply fixed top-0 left-0 w-full h-full flex items-center justify-center p-6 opacity-0 pointer-events-none -z-[1];

    &.display {
        @apply opacity-100 pointer-events-auto z-[60];
    }

    .bg {
        @apply absolute top-0 left-0 w-full h-full bg-black/50 cursor-pointer;
    }

    &-box {
        @apply relative flex flex-col items-center justify-center w-full pt-8 pb-6 px-5 max-w-[700px] min-h-[350px] bg-white rounded-[24px];

        .btn-group {
            @apply mt-6 flex flex-wrap items-center justify-center;

            button {
                @apply m-4;
            }
        }
    }

    &-title {
        @apply mt-6 mb-4 font-noto font-medium text-theme text-[24px] text-center leading-[1.45] md:text-[36px] lg:text-[40px];
    }

    &-paragraph {
        @apply block mb-2 font-noto font-normal text-[#434343] text-[18px] text-center leading-[1.45] md:text-[21px] md:mb-4 lg:text-[24px];

        .br {
            @apply block md:inline;
        }
    }
}

@keyframes float {
    0% {
        transform: translateX(0) translateY(-50%);
    }

    89.99% {
        transform: translateX(1592px) translateY(-50%);
    }

    90% {
        transform: translateX(0) translateY(-50%);
    }

    100% {
        transform: translateX(0) translateY(-50%);
    }
}

.loading {
    @apply fixed flex items-center justify-center top-0 left-0 w-full h-full bg-white z-[80] transition-opacity delay-[1.5s];

    &.loaded {
        @apply opacity-0 pointer-events-none;
    }

    &-box {
        .train {
            @apply relative w-[200px] h-[46px] overflow-hidden lg:w-[280px] lg:h-[65px];
        }

        .train-image {
            @apply absolute right-full top-1/2 w-[938px] h-[46px] animate-[float_2s_ease-in-out_infinite] lg:w-[1312px] lg:h-[65px];
        }

        img {
            @apply block w-full h-auto;
        }
    }

    &-bar {
        @apply w-[200px] h-2 mx-auto mb-5 rounded-full overflow-hidden border border-theme bg-theme/20 lg:w-[280px] lg:h-3;

        .progress  {
            @apply h-full bg-theme rounded-full;
        }
    }

    &-paragraph {
        @apply font-noto font-medium text-[#434343] text-[16px] text-center leading-[1.45] lg:text-[18px];
    }
}

.copyright {
    @apply font-noto font-normal text-center text-white text-[12px];
}